import React, { useState, useEffect } from "react";
import { Button } from "antd";
import GlobalIcons from "../SVG-icons/GlobalIcons";
import { useTranslation } from "react-i18next";

export default function Footer({
  layers,
  onSetLayers,
  alarmFilter,
  onSetAlarmFilter,
}) {
  const { t } = useTranslation();

  const onLayersChange = (key) => {
    const data = { ...layers };
    data[key] = !data[key];
    onSetLayers(data);
  };

  const onAlarmFilterChange = (type) => {
    const data = { ...alarmFilter };
    data[type] = !data[type];
    onSetAlarmFilter(data);
  };

  const AlarmFilterButton = ({ icon, type }) => {
    return (
      <a
        onClick={() => onAlarmFilterChange(type)}
        style={{
          backgroundColor: alarmFilter[type] ? "white" : "black",
          marginLeft: 8,
          borderRadius: 5,
          height: 33,
          width: 33,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </a>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        justifyContent: "flex-start",
        backgroundColor: "#DFDFDF",
      }}
    >
      <Button
        type="primary"
        style={{
          backgroundColor: layers.sensors ? "#FFFFFF" : "black",
          borderRadius: 5,
          borderWidth: 1,
          borderColor: "black",
          color: layers.sensors ? "black" : "#FFFFFF",
          fontSize: 18,
          marginRight: 20,
        }}
        onClick={() => onLayersChange("sensors")}
      >
        {t("Mostrar sensores")}
      </Button>
      <Button
        type="primary"
        style={{
          backgroundColor: layers.zones ? "#FFFFFF" : "black",
          borderRadius: 5,
          borderWidth: 1,
          borderColor: "black",
          color: layers.zones ? "black" : "#FFFFFF",
          fontSize: 18,
          marginRight: 20,
        }}
        onClick={() => onLayersChange("zones")}
      >
        {t("Mostrar zonas")}
      </Button>
      <AlarmFilterButton icon={GlobalIcons.neutralIcon()} type={"neutral"} />
      <AlarmFilterButton icon={GlobalIcons.warningIcon()} type={"friendly"} />
      <AlarmFilterButton icon={GlobalIcons.alertIcon()} type={"alert"} />
      <AlarmFilterButton icon={GlobalIcons.friendlyIcon()} type={"warning"} />
    </div>
  );
}
