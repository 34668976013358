import React from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import InhibitorIcons from "../SVG-icons/InhibitorIcons.js";
import InhibitorRadio from "./AlertMapMarkers/InhibitorRadio.js";

const InhibMarker = ({
  inhib,
  onInhibDragEnd,
  onSelectedInhib,
  selectedInhib,
}) => {
  const inhibitorIcon = (element) => {
    if (inhib.new) {
      return {
        icon: {
          iconUrl: InhibitorIcons.newInhibIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 100,
      };
    }

    if (selectedInhib !== null && selectedInhib.id === element.id) {
      return {
        icon: {
          iconUrl: InhibitorIcons.selectedInhibIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 100,
      };
    } else {
      return {
        icon: {
          iconUrl: InhibitorIcons.defaultInhibIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1,
      };
    }
  };
  return (
    <>
      <Marker
        key={inhib.id}
        position={[inhib.position.lat, inhib.position.lng]}
        icon={new L.Icon(inhibitorIcon(inhib).icon)}
        draggable={true}
        eventHandlers={{
          dragend: (event) => onInhibDragEnd(event, inhib.id),
          click: () => {
            console.log("SECTORS: ", inhib.sectors);
            onSelectedInhib(inhib);
          },
        }}
      />
      {selectedInhib !== null && selectedInhib.id === inhib.id && (
        <InhibitorRadio
          sectors={inhib.sectors}
          center={[inhib.position.lat, inhib.position.lng]}
        />
      )}
    </>
  );
};

export default InhibMarker;
