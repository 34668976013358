const HomeIcons = {
  neutralHome: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 18 17">
            <defs>
                <style>.b { fill: #a8329e; }</style>
            </defs>
            <polygon class="b" points="9.3333 0 0 8.6154 2.6666 8.6154 2.6666 16 16 16 16 8.6154 18.6666 8.6154 9.3333 0" />
            <path d="M9.3333,1.3609L16.109,7.6154H15V15H3.6666V7.6154H2.5576L9.3333,1.3609M9.3333,0L0,8.6154H2.6666V16H16V8.6154H18.6666L9.3333,0Z" />
            <path d="M11.1344,10.2585H7.5328v3.4447H5.9963V5.5417h1.5365v3.3724h3.6016v-3.3724h1.5365v8.1615h-1.5365V10.2585Z" />
        </svg>
    `),
  },
  warningHome: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 18 17">
            <defs>
                <style>.b { fill: #FFD700; }</style>
            </defs>
            <polygon class="b" points="9.3333 0 0 8.6154 2.6666 8.6154 2.6666 16 16 16 16 8.6154 18.6666 8.6154 9.3333 0" />
            <path d="M9.3333,1.3609L16.109,7.6154H15V15H3.6666V7.6154H2.5576L9.3333,1.3609M9.3333,0L0,8.6154H2.6666V16H16V8.6154H18.6666L9.3333,0Z" />
            <path d="M11.1344,10.2585H7.5328v3.4447H5.9963V5.5417h1.5365v3.3724h3.6016v-3.3724h1.5365v8.1615h-1.5365V10.2585Z" />
        </svg>
    `),
  },
  alertHome: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 18 17">
            <defs>
                <style>.b { fill: #e5310e; }</style>
            </defs>
            <polygon class="b" points="9.3333 0 0 8.6154 2.6666 8.6154 2.6666 16 16 16 16 8.6154 18.6666 8.6154 9.3333 0" />
            <path d="M9.3333,1.3609L16.109,7.6154H15V15H3.6666V7.6154H2.5576L9.3333,1.3609M9.3333,0L0,8.6154H2.6666V16H16V8.6154H18.6666L9.3333,0Z" />
            <path d="M11.1344,10.2585H7.5328v3.4447H5.9963V5.5417h1.5365v3.3724h3.6016v-3.3724h1.5365v8.1615h-1.5365V10.2585Z" />
        </svg>
    `),
  },
  friendlyHome: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 18 17">
            <defs>
                <style>.b { fill: #7CFC00; }</style>
            </defs>
            <polygon class="b" points="9.3333 0 0 8.6154 2.6666 8.6154 2.6666 16 16 16 16 8.6154 18.6666 8.6154 9.3333 0" />
            <path d="M9.3333,1.3609L16.109,7.6154H15V15H3.6666V7.6154H2.5576L9.3333,1.3609M9.3333,0L0,8.6154H2.6666V16H16V8.6154H18.6666L9.3333,0Z" />
            <path d="M11.1344,10.2585H7.5328v3.4447H5.9963V5.5417h1.5365v3.3724h3.6016v-3.3724h1.5365v8.1615h-1.5365V10.2585Z" />
        </svg>
    `),
  },
};

export default HomeIcons;
