import React from "react";
import { Polygon } from "react-leaflet";

export default function InhibitorRadio({ sectors, center }) {
  // Helper function to generate points along the rounded base
  const generateRoundedBase = (
    center,
    radius,
    startBearing,
    endBearing,
    numPoints
  ) => {
    const points = [];
    const angleIncrement = (endBearing - startBearing) / (numPoints - 1);

    for (let i = 0; i < numPoints; i++) {
      const bearing = startBearing + i * angleIncrement;
      points.push(calculatePoint(center[0], center[1], radius, bearing));
    }

    return points;
  };

  // Function to calculate a point given distance and bearing
  const calculatePoint = (lat, lng, distance, bearing) => {
    const R = 6371000; // Earth's radius in meters
    const bearingRad = degreesToRadians(bearing);

    const latRad = degreesToRadians(lat);
    const lngRad = degreesToRadians(lng);

    const newLat = Math.asin(
      Math.sin(latRad) * Math.cos(distance / R) +
        Math.cos(latRad) * Math.sin(distance / R) * Math.cos(bearingRad)
    );

    const newLng =
      lngRad +
      Math.atan2(
        Math.sin(bearingRad) * Math.sin(distance / R) * Math.cos(latRad),
        Math.cos(distance / R) - Math.sin(latRad) * Math.sin(newLat)
      );

    return [radiansToDegrees(newLat), radiansToDegrees(newLng)];
  };

  const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
  const radiansToDegrees = (radians) => (radians * 180) / Math.PI;

  // Function to create segmented triangles
  const createSegmentedTriangles = (data, segments) => {
    const segmentPolygons = [];
    const { lat, lng, range, bearing, amplitude } = data;

    // Calculate start and end bearings for the rounded base
    const startBearing = bearing - amplitude / 2;
    const endBearing = bearing + amplitude / 2;

    // Loop through each segment to create polygons
    for (let i = 0; i < segments; i++) {
      // Calculate the range for the current segment
      const innerRange = (range * i) / segments;
      const outerRange = (range * (i + 1)) / segments;

      // Calculate top and bottom points of the segment
      const innerBasePoints = generateRoundedBase(
        [lat, lng],
        innerRange,
        startBearing,
        endBearing,
        30
      );
      const outerBasePoints = generateRoundedBase(
        [lat, lng],
        outerRange,
        startBearing,
        endBearing,
        30
      );

      // Create the polygon for this segment
      const polygonCoordinates = [
        [lat, lng],
        ...outerBasePoints,
        ...innerBasePoints.reverse(), // Reverse to close the polygon
      ];
      segmentPolygons.push(polygonCoordinates);
    }

    return segmentPolygons;
  };

  const colorHaz = (index) => {
    let color = "white";
    if (index % 2 === 0) {
      color = "#00FFFF";
    }
    return color;
  };

  return (
    <>
      {sectors.map((sector, index) => {
        let colorSector = colorHaz(sector.id);
        const segmentedTriangles = createSegmentedTriangles(
          {
            lat: center[0],
            lng: center[1],
            range: sector.range,
            bearing: sector.heading,
            amplitude: sector.amplitude,
          },
          5 // Number of segments per triangle
        );

        return segmentedTriangles.map((polygon, i) => (
          <Polygon
            key={`${index}-${i}`}
            positions={polygon}
            fillColor="transparent"
            color={colorSector}
            pathOptions={{
              //   color: colorHaz(),
              weight: 1.5,
            }}
          />
        ));
      })}
    </>
  );
}
