import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DroneIcons from "../SVG-icons/DroneIcons";
import AlertTag from "../tags/AlertTag";
import HomeIcons from "../SVG-icons/HomeIcons";
import JoystickIcons from "../SVG-icons/JoystickIcons";
import { useTranslation } from "react-i18next";

export default function SidePanel({
  sensors,
  alerts,
  onSelectSensor,
  onHandleAlertTracking,
  selectedSensor,
  onSelectInhib,
  inhibitors,
  selectedInhibitor,
  zones,
  onSelectAlert,
  selectedAlert,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [showAlerts, setShowAlerts] = useState(false);
  const [showInhibs, setShowInhibs] = useState(false);
  const { t } = useTranslation();

  const toggleOptions = () => {
    onSelectSensor(null);
    setShowOptions(!showOptions);
  };

  // {id: 'DR01602028P4001109', name: 'RF-160 Interior', lat: 41.3956734, lng: 2.1733625, selected: true}

  const toggleAlerts = () => {
    onSelectAlert(null, 0);
    setShowAlerts(!showAlerts);
  };

  const toggleInhibs = () => {
    onSelectInhib(null);
    setShowInhibs(!showInhibs);
  };

  const findZone = (point, polygons) => {
    for (const polygon of polygons) {
      if (isPointInsideZone(point, polygon)) {
        return polygon.fillColor; // Return the polygon ID that contains the point
      }
    }
    return null;
  };
  const isPointInsideZone = (point, polygon) => {
    const { lat: x, lng: y } = point;
    const path = polygon.path;
    let inside = false;

    for (let i = 0, j = path.length - 1; i < path.length; j = i++) {
      const xi = path[i].lat,
        yi = path[i].lng;
      const xj = path[j].lat,
        yj = path[j].lng;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  const droneIcon = (element, type) => {
    if (element === undefined) {
      if (type === "remote") {
        return JoystickIcons.neutralJoystick.url;
      }
      return DroneIcons.neutralDrone.url;
    }

    const pointZone = findZone({ lat: element.lat, lng: element.lng }, zones);
    if (element.type === "home") {
      if (pointZone === "rgba(245,48,20,0.4)") {
        return HomeIcons.alertHome.url;
      }
      if (pointZone === "rgba(115,164,245,0.4)") {
        return HomeIcons.warningHome.url;
      }
      if (pointZone === "rgba(15, 140, 3, 0.4)") {
        return HomeIcons.friendlyHome.url;
      }
      return HomeIcons.neutralHome.url;
    } else if (element.type === "remote") {
      if (pointZone === "rgba(245,48,20,0.4)") {
        return JoystickIcons.alertJoystick.url;
      }
      if (pointZone === "rgba(115,164,245,0.4)") {
        return JoystickIcons.warningJoystick.url;
      }
      if (pointZone === "rgba(15, 140, 3, 0.4)") {
        return JoystickIcons.friendlyJoystick.url;
      }
      return JoystickIcons.neutralJoystick.url;
    }

    if (pointZone === "rgba(245,48,20,0.4)") {
      return DroneIcons.alertDrone.url;
    }
    if (pointZone === "rgba(115,164,245,0.4)") {
      return DroneIcons.warningDrone.url;
    }
    if (pointZone === "rgba(15, 140, 3, 0.4)") {
      return DroneIcons.friendlyDrone.url;
    }
    return DroneIcons.neutralDrone.url;
  };

  return (
    <div
      style={{
        flex: 1,
        overflowY: "auto",
        height: "100%",
        backgroundColor: "#DFDFDF",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
      }}
    >
      <div
        style={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {/* ALERTAS */}
        <div
          style={{
            marginBottom: "10px",
            backgroundColor: "#FFFFFF",
            color: "#13100D",
            fontWeight: "bold",
          }}
        >
          <a
            onClick={toggleAlerts}
            style={{
              display: "block",
              padding: "6px 10px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={showAlerts ? faChevronDown : faChevronRight}
              color="#FF1620"
              style={{ marginRight: 8 }}
            />
            {t("Monitoring")}
          </a>
          {showAlerts && (
            <div>
              {alerts.map((item, index) => {
                return (
                  <AlertTag
                    item={item}
                    index={index}
                    icon={droneIcon(item.points, item.type_alarm)}
                    selectItem={(item) => onSelectAlert(item, index)}
                    selectedItem={selectedAlert}
                  />
                );
              })}
            </div>
          )}
        </div>
        {selectedAlert !== null && (
          <div
            style={{
              padding: 23,
              overflow: "auto",
              backgroundColor: "black",
              marginTop: 20,
              color: "#FFFFFF",
            }}
          >
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {selectedAlert && selectedAlert.points && (
                <img
                  src={droneIcon(
                    selectedAlert.points,
                    selectedAlert.type_alarm
                  )}
                  width={10}
                  height={10}
                  style={{ marginRight: 8 }}
                />
              )}
              {t("Modelo")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.manufacturer} {selectedAlert.model}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Numero de serie")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.points &&
                  selectedAlert.points.serialNumber &&
                  selectedAlert.points.serialNumber}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Latitud")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.points &&
                  selectedAlert.points.lat &&
                  selectedAlert.points.lat}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Longitud")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.points &&
                  selectedAlert.points.lng &&
                  selectedAlert.points.lng}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Altura")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.points &&
                  selectedAlert.points.altitude &&
                  `${Math.ceil(selectedAlert.points.altitude)} m`}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Heading (grados respecto al norte)")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.points &&
                  selectedAlert.points.heading &&
                  `${Math.ceil(selectedAlert.points.heading)}º`}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Velocidad")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedAlert.points &&
                  selectedAlert.points.speed &&
                  `${Math.ceil(selectedAlert.points.speed)} m/s`}
              </span>
            </p>
          </div>
        )}

        {/* SENSORES */}
        <div
          style={{
            marginBottom: "10px",
            backgroundColor: "#FFFFFF",
            color: "#13100D",
            fontWeight: "bold",
          }}
        >
          <a
            onClick={toggleOptions}
            style={{
              display: "block",
              padding: "6px 10px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={showOptions ? faChevronDown : faChevronRight}
              color="#FF1620"
              style={{ marginRight: 8 }}
            />
            {t("Sensores")}
          </a>
          {showOptions && (
            <>
              <div
                style={{
                  overflow: "auto",
                  maxHeight: 200,
                }}
              >
                {sensors.length > 0 &&
                  sensors.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor:
                          selectedSensor != null &&
                          item.id === selectedSensor.id
                            ? "#ffa500"
                            : "#FFFFFF",
                        color:
                          selectedSensor != null &&
                          item.id === selectedSensor.id
                            ? "#FFFFFF"
                            : "#707070",
                        padding: 5,
                        cursor: "pointer",
                        transition: "background-color 0.3s ease", // Smooth transition
                      }}
                      onClick={() => {
                        onSelectSensor(item);
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {selectedSensor !== null && (
          <div
            style={{
              padding: 23,
              overflow: "auto",
              backgroundColor: "black",
              marginTop: 20, // Space between the list and the black box
              color: "#FFFFFF",
            }}
          >
            <p
              style={{
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Nombre")}:
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: 12,
                }}
              >
                {selectedSensor.name}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              Id:
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: 12,
                }}
              >
                {selectedSensor.id}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Latitud")}:
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: 12,
                }}
              >
                {selectedSensor.lat}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Longitud")}:
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: 12,
                }}
              >
                {selectedSensor.lng}
              </span>
            </p>
          </div>
        )}

        {/* INHIBIDORES */}
        <div
          style={{
            marginBottom: "10px",
            backgroundColor: "#FFFFFF",
            color: "#13100D",
            fontWeight: "bold",
          }}
        >
          <a
            onClick={toggleInhibs}
            style={{
              display: "block",
              padding: "6px 10px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={showInhibs ? faChevronDown : faChevronRight}
              color="#FF1620"
              style={{ marginRight: 8 }}
            />
            {t("Inhibidores")}
          </a>
          {showInhibs && (
            <>
              <div
                style={{
                  overflow: "auto",
                  maxHeight: 200,
                }}
              >
                {inhibitors.length > 0 &&
                  inhibitors.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor:
                          selectedSensor != null &&
                          item.id === selectedSensor.id
                            ? "#ffa500"
                            : "#FFFFFF",
                        color:
                          selectedSensor != null &&
                          item.id === selectedSensor.id
                            ? "#FFFFFF"
                            : "#707070",
                        padding: 5,
                        cursor: "pointer",
                        transition: "background-color 0.3s ease", // Smooth transition
                      }}
                      onClick={() => {
                        onSelectInhib(item);
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {selectedInhibitor !== null && (
          <div
            style={{
              padding: 23,
              overflow: "auto",
              backgroundColor: "black",
              marginTop: 20, // Space between the list and the black box
              color: "#FFFFFF",
            }}
          >
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Nombre")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedInhibitor.name}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              Id:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedInhibitor.id}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Latitud")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedInhibitor.lat}
              </span>
            </p>
            <p
              style={{
                marginBottom: "4px",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {t("Longitud")}:
              <span
                style={{ color: "#FFFFFF", fontWeight: "normal", fontSize: 12 }}
              >
                {selectedInhibitor.lng}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
