import React, { useState, useEffect, useRef } from "react";

import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon as Zone,
  LayersControl,
  useMapEvents,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { point } from "leaflet";

import SensorIcons from "../SVG-icons/SensorIcons";
import InhibitorIcons from "../SVG-icons/InhibitorIcons.js";
import Footer from "../Panels/Footer.js";
import SidePanel from "../Panels/SidePanel";
import ZonesServices from "../../services/ZonesServices.js";
import AlertMapUtils from "../utils/AlertMapUtils.js";
import { useAuth } from "../../providers/AuthContex.js";
import InhibitorServices from "../../services/InhibitorServices.js";
import SensorsServices from "../../services/SensorsServices.js";
import AlertServices from "../../services/AlertServices.js";
import { useMQTT } from "../../providers/MqttContex.js";
import HomeIcons from "../SVG-icons/HomeIcons.js";
import JoystickIcons from "../SVG-icons/JoystickIcons.js";
import DroneIcons from "../SVG-icons/DroneIcons.js";
import GlobalIcons from "../SVG-icons/GlobalIcons.js";
import InhibitorRadio from "./AlertMapMarkers/InhibitorRadio.js";
import { useTranslation } from "react-i18next";

export default function AlertMap() {
  const [sensorList, setSensorList] = useState([]);
  const [inhibitorsList, setInhibitorsList] = useState([]);
  const [zones, setZones] = useState(null);
  const [points, setPoints] = useState([]);
  const [radios, setRadios] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [selectedInhibitor, setSelectedInhibitor] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [alertsToIgnore, setAlertsToIgnore] = useState([]); // VER SI QUEDA O NO
  const [layers, setLayers] = useState({ sensors: true, zones: true });
  const [isProcessing, setIsProcessing] = useState(false);
  const [alertProcessing, setAlertProcessing] = useState(false);
  const [alarmFilter, setAlarmFilter] = useState({
    neutral: true,
    friendly: true,
    alert: true,
    warning: true,
  });
  const [selectedAlert, setSelectedAlert] = useState(null);

  const { group } = useAuth();
  const { client, isConnected, subscribe, unsubscribe } = useMQTT();
  const { BaseLayer } = LayersControl;
  const { t, i18n } = useTranslation();

  const markerRefs = useRef([]);

  const defaultCenter = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  let loc_topic = `Dedrone/${group.group_id}/Loc`;
  let rf_topic = `Dedrone/${group.group_id}/Rf`;
  let alarm_info = `Dedrone/${group.group_id}/AlarmInfo`;
  let end_topic = "/alert/end/topic/SQUADRONE";

  //SET DEL KEY ESC PARA DESELECCIONAR EL SENSOR/INHIBIDOR
  //SUBSCRIPCION Y DESUBSCRIPCION A TOPICS
  //GET DE LAS ZONAS, INHIBIDORES, SENSORES Y ALERTAS DEL REDIS

  const dangerScale = {
    "rgba(245,48,20,0.4)": 4,
    "rgba(115, 164, 245, 0.4)": 3,
    "rgba(15, 140, 3, 0.4)": 2,
    "no-zone": 1,
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setSelectedSensor(null);
        setSelectedInhibitor(null);
        setSelectedAlert(null);
      }
    };

    getSensors();
    // getCurrentAlerts();
    getZones();

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    console.log("ISCONNECTED: ", isConnected);
    // Subscribe to the topic
    if (isConnected) {
      subscribe(loc_topic);
      subscribe(rf_topic);
      subscribe(alarm_info);
      subscribe(end_topic);
      getInhibitors(client);

      client.on("disconnect", (err) => {
        setAlerts([]);
        setPoints([]);
      });

      client.on("error", (err) => {
        setAlerts([]);
        setPoints([]);
        //   client.end();
      });
    }

    return () => {
      unsubscribe(loc_topic);
      unsubscribe(rf_topic);
      unsubscribe(alarm_info);
      unsubscribe(end_topic);
    };
  }, [isConnected]);

  //INTERVAL PARA REVISION DE ALARMAS QUE NO ESTEN MAS DE 30 SEGUNDOS SIN UPDATE
  useEffect(() => {
    if (selectedAlert) {
      const index = points.findIndex(
        (point) => point.alertId === selectedAlert.alertId
      );
      const marker = markerRefs.current[index];
      if (marker) {
        marker.openPopup(); // Ensure the popup is opened when selectedAlert changes
      }
    }
    const interval = setInterval(() => {
      if (!isProcessing) {
        if (
          points.some((item) => new Date() - item.timestamp.getTime() >= 30000)
        ) {
          setIsProcessing(true);
          removePoints();
        }
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [points]);

  //SETTEO DEL ON MESSAGE
  useEffect(() => {
    if (selectedAlert) {
      const index = points.findIndex(
        (point) => point.alertId === selectedAlert.alertId
      );
      const marker = markerRefs.current[index];
      if (marker) {
        marker.openPopup(); // Ensure the popup is opened when selectedAlert changes
      }
    }
    const messageHandler = (topic, message) => {
      if (isProcessing) {
        console.warn(
          "MQTT message received but skipped due to ongoing processing."
        );
        return;
      }
      // console.log("MESSAGE RECEIVED: ", topic);
      const newMessage = JSON.parse(message.toString());
      // console.log(newMessage);
      if (newMessage.messageType === "sensor") {
        if (
          newMessage.data.info !== undefined &&
          newMessage.data.info.status === "end"
        ) {
          removePoints();
          removeAlerts();
          return;
        } else {
          onMessageSensor(newMessage);
          return;
        }
      } else {
        normalizeRadio(newMessage.data);
        return;
      }
    };

    // Attach the message handler
    client.on("message", messageHandler);

    // Clean up the listener when component unmounts or dependencies change
    return () => {
      client.off("message", messageHandler);
    };
  }, [points, alerts, zones]);

  useEffect(() => {
    const interval = setInterval(() => {
      removeAlerts();
    }, 5000);

    return () => clearInterval(interval);
  }, [alerts]);

  useEffect(() => {
    if (selectedAlert) {
      const index = points.findIndex(
        (point) => point.alertId === selectedAlert.alertId
      );
      const marker = markerRefs.current[index];
      if (marker) {
        marker.openPopup(); // Ensure the popup is opened when selectedAlert changes
      }
    }
  }, [selectedAlert]);

  function getDestinationLatLon(lat, lng, distance, bearing) {
    //NEW
    const R = 6371; // Earth radius in kilometers
    const rad = Math.PI / 180;

    const lat1 = lat * rad;
    const lon1 = lng * rad;

    const lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(distance / R) +
        Math.cos(lat1) * Math.sin(distance / R) * Math.cos(bearing * rad)
    );

    const lon2 =
      lon1 +
      Math.atan2(
        Math.sin(bearing * rad) * Math.sin(distance / R) * Math.cos(lat1),
        Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2)
      );

    return { lat: lat2 / rad, lng: lon2 / rad };
  }

  function createIsoscelesTriangle(A, B, baseLength) {
    //NEW
    const { lat: latA, lng: lonA } = A;
    const { lat: latB, lng: lonB } = B;

    // Calculate the bearing from A to B
    const rad = Math.PI / 180;
    const dLon = (lonB - lonA) * rad;
    const y = Math.sin(dLon) * Math.cos(latB * rad);
    const x =
      Math.cos(latA * rad) * Math.sin(latB * rad) -
      Math.sin(latA * rad) * Math.cos(latB * rad) * Math.cos(dLon);
    const bearingAB = (Math.atan2(y, x) / rad + 360) % 360;

    // Calculate the bearings to the two base points (P1 and P2)
    const baseBearing1 = (bearingAB + 90) % 360; // Perpendicular to A-B
    const baseBearing2 = (bearingAB - 90 + 360) % 360;

    // Calculate the coordinates of the two base points (P1 and P2)
    const halfBaseLength = baseLength / 2;
    const P1 = getDestinationLatLon(latB, lonB, halfBaseLength, baseBearing1);
    const P2 = getDestinationLatLon(latB, lonB, halfBaseLength, baseBearing2);

    return [P1, P2, A];
  }

  //HANDLER PARA EL MENSAJE QUE VIENE POR EL MQTT
  const onMessageSensor = async (message) => {
    //points borrado = 2
    //points borrado = 0

    //points dentro de message = 2
    if (isProcessing) {
      console.warn("Message skipped due to ongoing processing.");
      return;
    }

    setIsProcessing(true);
    try {
      for (let index = 0; index < message.data.length; index++) {
        let data = message.data[index];
        if (alertsToIgnore.includes(data.id)) {
          continue;
        }
        let radioS = [];

        let bearing = data.bearing;
        if (bearing != undefined && bearing != null) {
          let center = { lat: Number(data["lat"]), lng: Number(data["lng"]) };
          let sensorRange = sensorList.find(
            (element) => element.id === data.id
          );

          let obj = AlertMapUtils.bearingDistance(
            data["lat"],
            data["lng"],
            sensorRange.range / 1000, //KM
            bearing
          );
          let newPoint = {
            lat: Number(obj["latitude"]),
            lng: Number(obj["longitude"]),
          };

          let newPoint2 = AlertMapUtils.toEastPosition(
            newPoint.lat,
            newPoint.lng,
            0.5,
            bearing
          );

          let newPoint3 = AlertMapUtils.toWestPosition(
            newPoint.lat,
            newPoint.lng,
            0.5,
            bearing
          );

          let triangle = createIsoscelesTriangle(
            { lat: data["lat"], lng: data["lng"] },
            newPoint,
            1
          );

          // console.log("TRIANGULO: ", triangle); //NEW

          // console.log("PATH2: ", [center, newPoint2, newPoint3, newPoint]);
          let colorHaz =
            data.type_of_detection == "drone"
              ? "rgba(115,164,245,0.4)"
              : "rgba(245,48,20,0.4)";
          let colorBorde =
            data.type_of_detection == "drone" ? "#03378C" : "#F53014";
          radioS.push({
            alertId: data.alertId,
            path: [
              [triangle[0].lat, triangle[0].lng],
              [triangle[1].lat, triangle[1].lng],
              [triangle[2].lat, triangle[2].lng],
            ],
            fillColor: colorHaz,
            strokeColor: colorBorde,
            stroke: 0,
          });
        }
        setRadios((prevRadios) => [...prevRadios, ...radioS]);
        setSensorList((prevSensors) => {
          const sensorIndex = prevSensors.findIndex((e) => e.id === data.id);
          if (sensorIndex !== -1) {
            const updatedSensors = [...prevSensors];
            updatedSensors[sensorIndex] = {
              ...updatedSensors[sensorIndex],
              bearing:
                data.bearing === null
                  ? prevSensors[sensorIndex].bearing
                  : data.bearing,
              lat: data.lat,
              lng: data.lng,
              timestamp: new Date(),
            };
            return updatedSensors;
          } else {
            return [
              ...prevSensors,
              {
                id: data.id,
                lat: data.lat,
                lng: data.lng,
                timestamp: new Date(),
              },
            ];
          }
        });
      }
    } catch (error) {
      console.error("Error processing sensor message:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //REMOVE DE PUNTOS
  const removePoints = async () => {
    try {
      if (isProcessing) {
      }
      let pointsArray = points.filter(
        (item) => new Date() - item.timestamp.getTime() <= 30000
      );
      console.log("POINTSARRAY AFTER REMOVE: ", pointsArray);
      setPoints(pointsArray);
    } catch (error) {
      console.error("Error removing points:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //REMOVE DE ALERTAS
  const removeAlerts = async () => {
    try {
      if (isProcessing) {
      }
      let alertsArray = alerts.filter(
        (al) => new Date() - al.timestamp <= 30000
      );
      setAlerts(alertsArray);
    } catch (error) {
      console.error("Error removing alerts:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //GET DE LAS ZONAS PARA PINTAR EN EL MAPA
  const getZones = async () => {
    try {
      const response = await ZonesServices.getZonesByGroup();
      setZones(AlertMapUtils.normalizeZones(response.data));
    } catch (error) {
      console.log("getZones error: ", error);
    }
  };

  //GET GEL LISTADO DE SENSORES POR GRUPO
  const getSensors = async (process) => {
    try {
      const res = await SensorsServices.getSensors();
      setSensorList(AlertMapUtils.normalizeSensors(res.data));
    } catch (error) {
      console.log("getSensors error: ", error);
    }
  };

  //GET DEL LISTADO DE INHIBIDORES POR GRUPO
  const getInhibitors = async (client) => {
    try {
      const response = await InhibitorServices.getInhibitors();
      response.data.forEach((element) => {
        const topic = `atl/inhibitor${element.inhibitorid}/response`;
        client.subscribe(topic, (err) => {
          if (!err) {
            console.log(
              "Subscribed to: ",
              `atl/inhibitor${element.inhibitorid}/response`
            );
          } else {
            console.error("Subscription error:", topic);
          }
        });
      });
      setInhibitorsList(AlertMapUtils.normalizeInhibitors(response.data));
    } catch (error) {
      console.log("getInhibitors error: ", error);
    }
  };

  //GET DE LAS ALERTAS ACTUALES DE REDIS
  const getCurrentAlerts = async () => {
    try {
      const res = await AlertServices.getCurrentAlerts();
      const redisAlerts = [];
      res.data.forEach((element) => {
        redisAlerts.push({
          alertId: element.data.info.alertId,
          lat: element.data.point.lat,
          lng: element.data.point.lng,
          type: element.data.point.type,
          timestamp: new Date(),
        });
      });
      setPoints(redisAlerts);
    } catch (error) {
      console.log("getCurrentAlerts error: ", error);
    }
  };

  //NORAMLIZAR PUNTOS
  const normalizeRadio = (data) => {
    let alertId = data.info.alertId;
    let alertObj = makeAlert(data);
    // let changeS = sensors;
    let radioS = [];
    // let sensor = null;
    for (let element in data) {
      if (element == "info" || element == "timestamp") {
        continue;
      }
      if (element != "point") {
        // let sensorl = sensors.filter((e) => e.id == element);
        // let add = false;
        // if (sensorl.length > 0) {
        //   sensor = sensorl[0];
        // } else {
        //   add = true;
        //   sensor = { id: element };
        // }
        // sensor["lat"] = data[element]["lat"];
        // sensor["lng"] = data[element]["lng"];
        // sensor["alertId"] = alertId;
        // if (add) {
        //   changeS.push(sensor);
        // }
      } else {
        let pointZone = findZone(
          { lat: data[element]["lat"], lng: data[element]["lng"] },
          zones != null ? zones : []
        );

        pointZone = pointZone != null ? pointZone : "no-zone";

        let pointsS = [...points];
        let type =
          data[element]["type"] == undefined ? "drone" : data[element]["type"];
        let drone = pointsS.filter(
          (e) => e.alertId == alertId && e.type == type
        );

        if (drone.length > 0) {
          drone = drone[0];

          drone["lat"] = data[element]["lat"];
          drone["lng"] = data[element]["lng"];
          drone["timestamp"] = new Date();
          drone["speed"] = data[element]["speed"];
          drone["altitude"] = data[element]["altitude"];
          drone["heading"] = data[element]["heading"];
          drone["alarmType"] =
            dangerScale[pointZone.toString()] > drone["alarmType"]
              ? dangerScale[pointZone.toString()]
              : drone["alarmType"];
        } else {
          drone = {
            alertId: alertId,
            lat: data[element]["lat"],
            lng: data[element]["lng"],
            speed: data[element]["speed"],
            altitude: data[element]["altitude"],
            heading: data[element]["heading"],
            type: type,
            timestamp: new Date(),
            alarmType: dangerScale[pointZone],
          };
          pointsS.push(drone);
        }

        setPoints(pointsS);
      }

      // let bearing = data[element]["bearing"];
      // if (bearing) {
      //   let center = { lat: Number(data["lat"]), lng: Number(data["lng"]) };
      //   let obj = AlertMapUtils.bearingDistance(
      //     data["lat"],
      //     data["lng"],
      //     30,
      //     bearing
      //   );
      //   console.log(obj);
      //   let newPoint = {
      //     lat: Number(obj["latitude"]),
      //     lng: Number(obj["longitude"]),
      //   };

      //   let newPoint2 = AlertMapUtils.toEastPosition(
      //     newPoint.lat,
      //     newPoint.lng,
      //     4
      //   );
      //   let newPoint3 = AlertMapUtils.toWestPosition(
      //     newPoint.lat,
      //     newPoint.lng,
      //     4
      //   );
      //   let celeste = "#6570eb";
      //   radioS.push({
      //     alertId: alertId,
      //     path: [center, newPoint2, newPoint3, center],
      //     fillColor: celeste,
      //     strokeColor: celeste,
      //     stroke: 0,
      //   });
      // }
    }

    // setSensors(changeS);
    setRadios(radioS);

    // Ajust points
    const sensorsLocal = sensorList.filter((item) => item.alertId == alertId);
    // alertObj["sensors"] = sensorsLocal;

    const pointsLocal = points.filter((item) => item.alertId == alertId);
    // alertObj["points"] = pointsLocal;
    //setAlertActive(alerts);
  };

  //CREAR ALERTA
  const makeAlert = (data) => {
    if (alertProcessing) {
      console.log("RETURN");
      return;
    }
    setAlertProcessing(true);
    try {
      const alertsLocal = alerts.filter(
        (item) => item.info.alertId === data.info.alertId
      );
      const alertSensors = Object.keys(data).filter(
        (key) => key !== "point" && key !== "info"
      );

      if (alertsLocal.length === 0) {
        let alertObj = {
          alertId: data.info.alertId,
          timestamp: Date.now(),
          info: data.info,
          points: data.point,
          manufacturer:
            data.drone_info.manufacturer != undefined
              ? data.drone_info.manufacturer
              : "",
          model:
            data.drone_info.model != undefined ? data.drone_info.model : "",
          startTime: new Date(),
          type_alarm:
            data.drone_info.type != undefined ? data.drone_info.type : "",
          sensors_id: [...alertSensors],
        };

        const alertsL = [...alerts, alertObj];
        setAlerts(alertsL);

        return alertObj;
      } else {
        const existingAlert = { ...alertsLocal[0] };
        existingAlert.info = { ...existingAlert.info, ...data.info };
        existingAlert.sensors_id = [...alertSensors];
        existingAlert.timestamp = new Date();
        existingAlert.points = data.point;
        existingAlert.manufacturer = data.drone_info.manufacturer;
        existingAlert.model = data.drone_info.model;
        existingAlert.type = data.drone_info.type;

        const updatedAlerts = [...alerts].map((alert) => {
          if (alert.alertId === existingAlert.alertId) {
            return existingAlert;
          } else {
            return alert;
          }
        });

        setAlerts(updatedAlerts);

        return existingAlert;
      }
    } catch (error) {
      console.log("makeAlert error: ", error);
    } finally {
      setAlertProcessing(false);
    }
  };

  const onSelectSensor = (sensor) => {
    setSelectedInhibitor(null);
    setSelectedAlert(null);
    setSelectedSensor(sensor);
  };

  const onSelectInhibitor = (inhib) => {
    setSelectedSensor(null);
    setSelectedAlert(null);
    setSelectedInhibitor(inhib);
  };

  const onSelectAlert = (alert, index) => {
    setSelectedAlert(alert);
    setSelectedSensor(null);
    setSelectedInhibitor(null);
  };

  //FUNCION PARA SELECCIONAR ALARMAS QUE NO QUERES TRACKEAR
  const handleAlertTracking = (id) => {
    return;
    // if(alertsToIgnore.includes(id)){
    //   let alertsList = [...alertsToIgnore]
    //   alertsList = alertsList.filter(item => item !== id);
    //   setAlertsToIgnore(alertsList)
    // } else {
    //   let alertsList = [...alertsToIgnore]
    //   alertsList.push(id)
    //   setAlertsToIgnore(alertsList)
    // }
  };

  //FUNCION QUE RETORNA EL ICONO CORRESPONDIENTE DEL PUNTO (HOME, PILOTO O REMOTE)
  const isPointInsideZone = (point, polygon) => {
    const { lat: x, lng: y } = point;
    const path = polygon.path;
    let inside = false;

    for (let i = 0, j = path.length - 1; i < path.length; j = i++) {
      const xi = path[i].lat,
        yi = path[i].lng;
      const xj = path[j].lat,
        yj = path[j].lng;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };
  const findZone = (point, polygons) => {
    for (const polygon of polygons) {
      if (isPointInsideZone(point, polygon)) {
        return polygon.fillColor; // Return the polygon ID that contains the point
      }
    }
    return null;
  };
  const droneIcon = (element) => {
    const pointZone = element.alarmType;
    if (element.type === "home") {
      if (pointZone == 4) {
        return {
          icon: {
            iconUrl: alarmFilter.alert
              ? HomeIcons.alertHome.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 10000,
        };
      }
      if (pointZone == 3) {
        return {
          icon: {
            iconUrl: alarmFilter.warning
              ? HomeIcons.warningHome.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 10000,
        };
      }

      if (pointZone == 2) {
        return {
          icon: {
            iconUrl: alarmFilter.friendly
              ? HomeIcons.friendlyHome.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 10000,
        };
      }
      return {
        icon: {
          iconUrl: alarmFilter.neutral
            ? HomeIcons.neutralHome.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 10000,
      };
    } else if (element.type === "remote") {
      if (pointZone == 4) {
        return {
          icon: {
            iconUrl: alarmFilter.alert
              ? JoystickIcons.alertJoystick.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 100000,
        };
      }
      if (pointZone == 3) {
        return {
          icon: {
            iconUrl: alarmFilter.warning
              ? JoystickIcons.warningJoystick.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 100000,
        };
      }
      if (pointZone == 2) {
        return {
          icon: {
            iconUrl: alarmFilter.friendly
              ? JoystickIcons.friendlyJoystick.url
              : GlobalIcons.transparent.url,
            iconSize: [25, 25],
            iconAnchor: [12, 20],
          },
          layer: 100000,
        };
      }
      return {
        icon: {
          iconUrl: alarmFilter.neutral
            ? JoystickIcons.neutralJoystick.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 100000,
      };
    }

    if (pointZone == 4) {
      return {
        icon: {
          iconUrl: alarmFilter.alert
            ? DroneIcons.alertDrone.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }
    if (pointZone == 3) {
      return {
        icon: {
          iconUrl: alarmFilter.warning
            ? DroneIcons.warningDrone.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }
    if (pointZone == 2) {
      return {
        icon: {
          iconUrl: alarmFilter.friendly
            ? DroneIcons.friendlyDrone.url
            : GlobalIcons.transparent.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }
    return {
      icon: {
        iconUrl: alarmFilter.neutral
          ? DroneIcons.neutralDrone.url
          : GlobalIcons.transparent.url,
        iconSize: [25, 25],
        iconAnchor: [12, 20],
      },
      layer: 1000000,
    };
  };
  const sensorIcon = (element) => {
    if (
      alerts.some(
        (alert) =>
          alert.sensors_id.includes(element.id) &&
          new Date() - alert.timestamp <= 1000
      )
    ) {
      return {
        icon: {
          iconUrl: SensorIcons.alertSensorIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 100,
      };
    }
    if (selectedSensor !== null && selectedSensor.id === element.id) {
      return {
        icon: {
          iconUrl: SensorIcons.selectedSensorIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    } else {
      return {
        icon: {
          iconUrl: SensorIcons.defaultSensorIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1,
      };
    }
  };
  const inhibitorIcon = (element) => {
    if (selectedInhibitor !== null && selectedInhibitor.id === element.id) {
      return {
        icon: {
          iconUrl: InhibitorIcons.selectedInhibIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 100,
      };
    } else {
      return {
        icon: {
          iconUrl: InhibitorIcons.defaultInhibIcon.url,
          iconSize: [25, 25],
          iconAnchor: [12, 20],
        },
        layer: 1,
      };
    }
  };

  return (
    <div
      style={{
        height: "93vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <SidePanel
          sensors={sensorList}
          alerts={alerts}
          inhibitors={inhibitorsList}
          onSelectSensor={(item) => onSelectSensor(item)}
          onSelectInhib={(item) => onSelectInhibitor(item)}
          onSelectAlert={(item, index) => {
            onSelectAlert(item, index);
          }}
          onHandleAlertTracking={(id) => {
            handleAlertTracking(id);
          }}
          selectedSensor={selectedSensor}
          selectedInhibitor={selectedInhibitor}
          selectedAlert={
            selectedAlert != null
              ? alerts.find((item) => item.alertId === selectedAlert.alertId) !=
                undefined
                ? alerts.find((item) => item.alertId === selectedAlert.alertId)
                : null
              : null
          }
          zones={zones}
        />
        <div style={{ flex: 3, backgroundColor: "#e9ecef", height: "100%" }}>
          {zones && points && (
            <MapContainer
              center={[defaultCenter.lat, defaultCenter.lng]}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
            >
              <LayersControl position="topright">
                <BaseLayer name="Politico">
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                </BaseLayer>
                <BaseLayer checked name="Satelite">
                  <TileLayer
                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    attribution="&copy; Google"
                  />
                </BaseLayer>
              </LayersControl>
              {/* SENSORES EN EL MAPA */}
              {layers.sensors &&
                sensorList.map((element) => {
                  const { icon, layer } = sensorIcon(element);
                  return (
                    <Marker
                      key={element.id}
                      position={[element.lat, element.lng]}
                      icon={new L.Icon(icon)}
                      zIndexOffset={layer} // Higher zIndex for the active marker
                      eventHandlers={{
                        click: () => {
                          console.log(element);
                          onSelectSensor(element);
                        },
                      }}
                    />
                  );
                })}
              {/* INHIBIDORES */}
              {inhibitorsList.map((element) => {
                const icon = inhibitorIcon(element);
                return (
                  <>
                    <Marker
                      key={element.id}
                      position={[element.lat, element.lng]}
                      icon={new L.Icon(icon.icon)}
                      zIndexOffset={icon.layer}
                      eventHandlers={{
                        click: () => {
                          onSelectInhibitor(element);
                        },
                      }}
                    />
                    <InhibitorRadio
                      sectors={element.sectors}
                      center={[element.lat, element.lng]}
                    />
                  </>
                );
              })}

              {/* PUNTOS EN EL MAPA (HOME, DRONE Y PILOTO) */}
              {points.map((element, index) => {
                const icon = droneIcon(element);
                return (
                  <Marker
                    key={element.id}
                    position={[element.lat, element.lng]}
                    icon={new L.Icon(icon.icon)}
                    zIndexOffset={icon.layer}
                    ref={(el) => (markerRefs.current[index] = el)}
                    eventHandlers={{
                      click: () => {
                        onSelectAlert(element, index);

                        const alert = selectedAlert
                          ? alerts.find(
                              (item) => item.alertId === selectedAlert.alertId
                            ) || null
                          : null;

                        if (alert) {
                          const popupContent = `
                            <div style="text-align: center;">
                              <div>${t("Velocidad")}: ${Math.ceil(
                            alert.speed
                          )} m/s</div>
                              <div>${t("Altura")}: ${Math.ceil(
                            alert.altitude
                          )} m</div>
                            </div>
                          `;
                          markerRefs.current[index]
                            .bindPopup(popupContent, { offset: [0, -30] }) // Adjust the offset here
                            .openPopup();
                        }
                      },
                    }}
                  ></Marker>
                );
              })}
              {/* ZONAS EN EL MAPA */}
              {layers.zones &&
                zones.map((element) => {
                  return (
                    <Zone
                      key={element.id} // Make sure to add a key if this is a list
                      pathOptions={{
                        color: element.strokeColor,
                        fillColor: element.fillColor,
                      }}
                      positions={element.zoneCordinates}
                    />
                  );
                })}
              {/* HAZ DEL SENSOR */}
              {radios.map((element) => (
                <Zone
                  key={element.id} // Make sure to add a key if this is a list
                  pathOptions={{
                    color: element.strokeColor,
                    fillColor: element.fillColor,
                  }}
                  positions={element.path}
                />
              ))}
            </MapContainer>
          )}
        </div>
      </div>
      <Footer
        layers={layers}
        onSetLayers={(e) => setLayers(e)}
        alarmFilter={alarmFilter}
        onSetAlarmFilter={(e) => setAlarmFilter(e)}
      />
    </div>
  );
}
