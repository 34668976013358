import { useEffect } from "react";

var W3CWebSocket = require("websocket").w3cwebsocket;

export default function Mqtt(props) {
  useEffect(() => {
    const socketProtocol =
      window.location.protocol === "https:" ? "wss:" : "ws:";
    const echoSocketUrl =
      socketProtocol + "//" + window.location.hostname + ":3002/echo/";
    console.log("SOCKET: ", echoSocketUrl);
    const mqtt = new WebSocket(echoSocketUrl);
    mqtt.onopen = () => {
      console.log("connected");
    };
    mqtt.onclose = () => {
      console.log("close");
    };
    mqtt.onmessage = (e) => {
      let message = JSON.parse(e.data);
      console.log(message);
      props.onMessage(message.type, message.data);
      /*
          if (message.type == 'points') {
            normalizePoints(message.data);
          } else if (message.type == 'radio') {
            normalizeRadio(message.data);
          }
            */
    };
  }, []); // <-- empty dependency array
}
