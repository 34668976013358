const JoystickIcons = {
  neutralJoystick: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 55 50">
            <path fill="#a8329e" stroke="black" stroke-width="2.4" 
                    d="M36 16.8A7.2 7.2 0 0 1 28.8 24A7.2 7.2 0 0 1 21.6 16.8A7.2 7.2 0 0 1 36 16.8z"/>

            <path fill="#a8329e" stroke="black" stroke-width="2.4" 
                    d="M28.8 48L14.4 28.8L43.2 28.8Z"/>
        </svg>
    `),
  },
  warningJoystick: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 55 50">
            <path fill="#FFD700" stroke="black" stroke-width="2.4" 
                    d="M36 16.8A7.2 7.2 0 0 1 28.8 24A7.2 7.2 0 0 1 21.6 16.8A7.2 7.2 0 0 1 36 16.8z"/>

            <path fill="#FFD700" stroke="black" stroke-width="2.4" 
                    d="M28.8 48L14.4 28.8L43.2 28.8Z"/>
        </svg>
    `),
  },
  alertJoystick: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 55 50">
            <path fill="#e5310e" stroke="black" stroke-width="2.4" 
                    d="M36 16.8A7.2 7.2 0 0 1 28.8 24A7.2 7.2 0 0 1 21.6 16.8A7.2 7.2 0 0 1 36 16.8z"/>

            <path fill="#e5310e" stroke="black" stroke-width="2.4" 
                    d="M28.8 48L14.4 28.8L43.2 28.8Z"/>
        </svg>
    `),
  },
  friendlyJoystick: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 55 50">
            <path fill="#7CFC00" stroke="black" stroke-width="2.4" 
                    d="M36 16.8A7.2 7.2 0 0 1 28.8 24A7.2 7.2 0 0 1 21.6 16.8A7.2 7.2 0 0 1 36 16.8z"/>

            <path fill="#7CFC00" stroke="black" stroke-width="2.4" 
                    d="M28.8 48L14.4 28.8L43.2 28.8Z"/>
        </svg>
    `),
  },
};

export default JoystickIcons;
