import api from "./api";

const ZonesServices = {
  getZones: async () => {
    const response = await api({ baseURL: "/services/zones", method: "GET" });
    return response;
  },
  getZonesByGroup: async () => {
    const response = await api({
      baseURL: "/services/zones/group",
      method: "GET",
    });
    return response;
  },
  linkZoneWithGroup: async (body) => {
    const response = await api({
      baseURL: "/services/link/group/zone",
      method: "POST",
      data: body,
    });
    return response;
  },
  unlinkZoneWithGroup: async (zone_id, group_id) => {
    const response = await api({
      baseURL: `/services/link/group/zone?zoneid=${zone_id}&groupid=${group_id}`,
      method: "DELETE",
    });
    return response;
  },
};

export default ZonesServices;
