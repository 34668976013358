const GlobalIcons = {
  transparent: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="400"
            height="400"
            viewBox="0 0 124 124"
            fill="none"
        >
            <rect width="124" height="124" rx="24" fill="transparent" />
        </svg>
    `),
  },
  neutralIcon: () => {
    return (
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 4.21281L7.5 0.421143L1 4.21281V10.7872L7.5 14.5788L14 10.7872V4.21281Z"
          fill="#a8329e"
        />
      </svg>
    );
  },
  friendlyIcon: () => {
    return (
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 4.21281L7.5 0.421143L1 4.21281V10.7872L7.5 14.5788L14 10.7872V4.21281Z"
          fill="#7CFC00"
        />
      </svg>
    );
  },
  alertIcon: () => {
    return (
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 4.21281L7.5 0.421143L1 4.21281V10.7872L7.5 14.5788L14 10.7872V4.21281Z"
          fill="#e5310e"
        />
      </svg>
    );
  },

  warningIcon: () => {
    return (
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 4.21281L7.5 0.421143L1 4.21281V10.7872L7.5 14.5788L14 10.7872V4.21281Z"
          fill="#FFD700"
        />
      </svg>
    );
  },
};

export default GlobalIcons;
