import React, { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthContex";
import { useKeycloak } from "../../providers/KeycloakContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import logo from "../img/sq-logo.jpeg";
import { useTranslation } from "react-i18next";

function Header() {
  const [open, setOpen] = useState(false);
  const { logout, group } = useAuth();
  const keycloak = useKeycloak();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (group === null) {
      navigate("/login");
    }
  }, [group]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const logOut = async () => {
    try {
      logout();
      if (keycloak) {
        await keycloak.logout({});
        console.log("CHAU");
        navigate("/");
      } else {
        console.log("Keycloak instance is not available");
      }
    } catch (error) {
      console.log("logOut error: ", error);
    }
  };

  return (
    <header
      style={{
        display: "flex",
        padding: "10px",
        height: "5vh",
        alignItems: "center",
        backgroundColor: "#00B4D8",
        flexDirection: "row",
        position: "relative",
        background:
          "transparent linear-gradient(0deg, #000000 0%, #5F5F5F 71%, #878787 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      <img src={logo} style={{ height: "40px" }} />
      {group != null && group != undefined && group.user_name != undefined && (
        <span
          style={{ position: "absolute", right: 45, top: 25, color: "#FFFFFF" }}
        >
          <FontAwesomeIcon
            icon={faUser}
            color="#FFFFFF"
            size="sm"
            style={{ marginRight: 8 }}
          />
          {group.user_name}
        </span>
      )}
      <div
        onClick={() => {
          showDrawer();
        }}
        style={{ position: "absolute", right: 15, top: 25 }}
      >
        <FontAwesomeIcon icon={faBars} color="#FFFFFF" />
      </div>

      <Drawer onClose={onClose} open={open}>
        {group != null &&
          group != undefined &&
          group.user_name != undefined && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>
                {group.roles.includes("Configurator") ||
                  (group.roles.includes("Admin") && (
                    <a
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color:
                          location.pathname === "/adm/security-group"
                            ? "#00B4D8"
                            : "black",
                      }}
                      onClick={() => {
                        onClose();
                        navigate("/adm/security-group");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        color="#00B4D8"
                        size="lg"
                      />
                      <p
                        style={{
                          fontWeight: "bold",
                          marginLeft: 5,
                          fontSize: 25,
                        }}
                      >
                        {t("Usuarios y grupos")}
                      </p>
                    </a>
                  ))}
                {group.roles.includes("Configurator") ||
                  (group.roles.includes("Admin") && (
                    <a
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color:
                          location.pathname === "/map-editor"
                            ? "#00B4D8"
                            : "black",
                      }}
                      onClick={() => {
                        onClose();
                        navigate("/map-editor");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        color="#00B4D8"
                        size="lg"
                      />
                      <p
                        style={{
                          fontWeight: "bold",
                          marginLeft: 5,
                          fontSize: 25,
                        }}
                      >
                        {t("Map editor")}
                      </p>
                    </a>
                  ))}
                <a
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: location.pathname === "/" ? "#00B4D8" : "black",
                  }}
                  onClick={() => {
                    onClose();
                    navigate("/");
                  }}
                >
                  <FontAwesomeIcon icon={faHome} color="#00B4D8" size="lg" />
                  <p
                    style={{ fontWeight: "bold", marginLeft: 5, fontSize: 25 }}
                  >
                    {t("Home")}
                  </p>
                </a>
                <a
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faBell} color="#00B4D8" size="lg" />
                  <p
                    style={{ fontWeight: "bold", marginLeft: 5, fontSize: 25 }}
                  >
                    {t("Alertas")}
                  </p>
                </a>
                <a
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faFile} color="#00B4D8" size="lg" />
                  <p
                    style={{ fontWeight: "bold", marginLeft: 5, fontSize: 25 }}
                  >
                    {t("Reportes")}
                  </p>
                </a>
              </div>

              <a
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                  color: "#D80000",
                }}
                onClick={() => logOut()}
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  color="#D80000"
                  size="lg"
                />
                <p
                  style={{
                    fontWeight: "bold",
                    marginLeft: 5,
                    fontSize: 25,
                    color: "#D80000",
                  }}
                >
                  {t("Cerrar sesión")}
                </p>
              </a>
            </div>
          )}
      </Drawer>
    </header>
  );
}

export default Header;
