import api from "./api";

const SensorsServices = {
    getSensors: async() => {
        const response = await api({baseURL: '/services/sensors', method: 'GET'})
        return response
    },
    postSensor: async(body) => {
        const response = await api({
            baseURL: '/api/v1/conf/sensors',
            method: 'POST', 
            data: body
        })
        return response
    },
    putSensor: async(body) => {
        const response = await api({
            baseURL: `/api/v1/conf/sensors/${body.sensor_id}`,
            method: 'PUT', 
            data: body
        })
        return response
    },
    deleteSensor: async(body) => {
        const response = await api({
            baseURL: `/api/v1/conf/sensors/${body.sensor_id}`,
            method: 'DELETE', 
        })
        return response
    },
    linkSensorWithGroup: async(body) => {
        const response = await api({
            baseURL: '/services/link/group/sensor',
            method: 'POST', 
            data: body
        })
        return response
    },
}

export default SensorsServices