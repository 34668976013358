import React from "react";

import MapEditorComponent from "../../components/Maps/MapEditorComponent";
import Header from "../../components/Panels/Header";

export default function MapEditorScreen() {
  return (
    <div>
      <Header />
      <MapEditorComponent />
    </div>
  );
}
