import logo from "../logo.svg";
import DroneMap from "../components/DroneMap";
import React, { useState, useEffect } from "react";

import AlertMap from "../components/Maps/AlertMap";
import Header from "../components/Panels/Header";

function Home() {
  const [authenticated, setAuthenticated] = useState(undefined);

  const logout = () => {};

  return (
    <div>
      <Header />
      <AlertMap />
      {/* <DroneMap logout={logout} authenticated={authenticated}/> */}
    </div>
  );
}

export default Home;
