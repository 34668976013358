import React, { createContext, useState, useContext, useEffect } from "react";

// Create the context
const AuthContext = createContext(null);

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [group, setGroup] = useState(null);

  const login = (token, refresh) => {
    document.cookie = `a_token=${token}; path=/;`;
    document.cookie = `r_token=${refresh}; path=/;`;
    setAccessToken(token);
  };

  const logout = () => {
    document.cookie = `a_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `r_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    setAccessToken(null);
    setGroup(null);
    console.log("Logged out");
  };

  const saveGroup = (group) => {
    setGroup(group);
  };

  return (
    <AuthContext.Provider
      value={{ accessToken, login, logout, group, saveGroup }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
