import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import Keycloak from "keycloak-js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContex";

const KeycloakContext = createContext(null);
const keycloakConfig = {
  url: "https://keycloak.squadrone.es/",
  realm: "SQUADRONE",
  clientId: "react-client",
};

export const KeycloakProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const { login, logout } = useAuth();

  useEffect(() => {
    const kc = new Keycloak(keycloakConfig);
    kc.init({
      onLoad: "check-sso",
      // silentCheckSsoRedirectUri: `${window.location.origin}/login`,
    })
      .then((authenticated) => {
        if (authenticated) {
          setAuthenticated(true);
          setKeycloak(kc);
          login(kc.token, kc.refreshToken);
        } else {
          kc.login();
        }
      })
      .catch((error) => {
        console.error("Keycloak initialization error:", error);
      });
  }, []);

  const refreshToken = useCallback(() => {
    if (!keycloak) return;

    keycloak
      .updateToken(60)
      .then((refreshed) => {
        if (refreshed) {
          console.log("Token refreshed");
          login(keycloak.token, keycloak.refreshToken);
        } else {
          console.warn("Token is still valid");
        }
      })
      .catch((error) => {
        console.error("Failed to refresh token", error);
        handleLogout();
        // navigate("/login");
      });
  }, [keycloak]);

  const handleLogout = useCallback(() => {
    if (keycloak) {
      keycloak.logout().then(() => {
        logout();
        navigate("/login"); // Redirect to login page after logout
      });
    }
  }, [keycloak, navigate]);

  useEffect(() => {
    if (authenticated) {
      refreshToken();

      const interval = setInterval(refreshToken, 30000);
      return () => clearInterval(interval);
    }
  }, [authenticated, refreshToken]);

  return (
    <KeycloakContext.Provider value={keycloak}>
      {children}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => useContext(KeycloakContext);
