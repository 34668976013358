// src/MQTTContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import mqtt from "mqtt";

// Create the Context
const MQTTContext = createContext();

// Create a provider component
export const MQTTProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const mqttClient = mqtt.connect(`wss://mqtt.squadrone.es:443`, {
      keepalive: 60, // Keep-alive en segundos. Aquí está configurado a 60 segundos.
      reconnectPeriod: 1000, // Tiempo de reconexión en milisegundos (1 segundo).
      clean: true, // Mantener las suscripciones al reconectar
    });

    setClient(mqttClient);

    mqttClient.on("connect", () => {
      console.log("Connected to MQTT broker");
      setIsConnected(true);
    });

    // mqttClient.on("error", (err) => {
    //   console.error("Connection error: ", err);
    //   mqttClient.end();
    // });

    mqttClient.on("reconnect", () => {
      console.log("Reconnecting...");
    });

    // Clean up the connection when the component unmounts
    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);

  // Function to subscribe to a new topic
  const subscribe = (topic) => {
    if (client && isConnected) {
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Subscribed to ${topic}`);
        } else {
          console.error(`Subscription error: ${topic}`);
        }
      });
    } else {
      console.warn("Cannot subscribe, client not connected");
    }
  };

  // Function to unsubscribe from a topic
  const unsubscribe = (topic) => {
    if (client && isConnected) {
      client.unsubscribe(topic, (err) => {
        if (!err) {
          console.log(`Unsubscribed from ${topic}`);
        } else {
          console.error(`Unsubscribe error: ${err}`);
        }
      });
    } else {
      console.warn("Cannot unsubscribe, client not connected");
    }
  };

  return (
    <MQTTContext.Provider
      value={{
        client,
        isConnected,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </MQTTContext.Provider>
  );
};

// Custom hook to use the MQTT context
export const useMQTT = () => {
  return useContext(MQTTContext);
};
