const SensorIcons = {
    newSensorIcon: {
        url: 'data:image/svg+xml;charset=UTF-8,' + 
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
            <!-- Circle with #0077B6 background and white border -->
            <circle cx="50" cy="50" r="45" fill="#AFE1AF" stroke="white" stroke-width="5" />
            <path fill="#F0F0F0" opacity="1.000000" stroke="none" d="
              M50.924416,34.184502 
              C52.378639,43.081142 53.810932,51.537689 55.243221,59.994232 
              C55.643261,60.082031 56.043301,60.169827 56.443336,60.257626 
              C57.976986,55.535561 59.329063,50.743435 61.113857,46.118286 
              C62.148499,43.437088 63.879124,41.024464 65.296547,38.490974 
              C67.177879,40.695656 69.456375,42.677273 70.848793,45.156536 
              C73.015907,49.015163 74.580902,53.211956 77.144318,57.869362 
              C78.668846,50.186451 83.995430,50.603954 89.152847,51.143703 
              C90.453880,51.279865 91.625725,52.650551 92.856934,53.453926 
              C91.861115,54.614372 90.675743,56.889599 89.899689,56.757633 
              C84.069611,55.766289 83.415703,60.412109 81.520126,63.826092 
              C80.303482,66.017288 78.888641,68.098442 77.562645,70.228920 
              C76.974403,70.295830 76.386162,70.362740 75.797920,70.429657 
              C72.804649,64.316956 69.811386,58.204262 66.217270,50.864552 
              C63.897652,58.303738 61.917049,64.619888 59.964138,70.944588 
              C59.489799,72.480766 59.414837,74.238503 58.596275,75.542992 
              C57.750336,76.891106 56.222668,77.811440 54.993225,78.918907 
              C54.006592,77.637581 52.491631,76.490639 52.125557,75.051468 
              C50.608898,69.088913 49.313118,63.061775 48.176056,57.013268 
              C46.886635,50.154285 45.853580,43.247105 44.499760,35.101551 
              C42.810196,42.508347 41.247185,48.729115 40.012257,55.014343 
              C39.288544,58.697708 37.647739,60.455673 33.687782,60.034370 
              C30.721390,59.718773 27.647732,60.273529 24.716999,59.822460 
              C23.379597,59.616615 22.271688,57.919720 21.059690,56.899101 
              C22.261553,55.946777 23.407120,54.256191 24.674232,54.159573 
              C34.332645,53.423088 34.210835,53.495628 36.100433,43.453812 
              C37.564560,35.673080 39.258415,27.926208 41.197109,20.251936 
              C41.591961,18.688934 43.535263,17.517111 44.764751,16.164963 
              C45.833427,17.608376 47.403339,18.905935 47.873993,20.523367 
              C49.135086,24.857168 49.925255,29.328005 50.924416,34.184502 
            z"/>
          </svg>
        `),
      },
      selectedSensorIcon: {
        url: 'data:image/svg+xml;charset=UTF-8,' + 
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
            <!-- Circle with #0077B6 background and white border -->
            <circle cx="50" cy="50" r="45" fill="#ffa500" stroke="white" stroke-width="5" />
            <path fill="#F0F0F0" opacity="1.000000" stroke="none" d="
              M50.924416,34.184502 
              C52.378639,43.081142 53.810932,51.537689 55.243221,59.994232 
              C55.643261,60.082031 56.043301,60.169827 56.443336,60.257626 
              C57.976986,55.535561 59.329063,50.743435 61.113857,46.118286 
              C62.148499,43.437088 63.879124,41.024464 65.296547,38.490974 
              C67.177879,40.695656 69.456375,42.677273 70.848793,45.156536 
              C73.015907,49.015163 74.580902,53.211956 77.144318,57.869362 
              C78.668846,50.186451 83.995430,50.603954 89.152847,51.143703 
              C90.453880,51.279865 91.625725,52.650551 92.856934,53.453926 
              C91.861115,54.614372 90.675743,56.889599 89.899689,56.757633 
              C84.069611,55.766289 83.415703,60.412109 81.520126,63.826092 
              C80.303482,66.017288 78.888641,68.098442 77.562645,70.228920 
              C76.974403,70.295830 76.386162,70.362740 75.797920,70.429657 
              C72.804649,64.316956 69.811386,58.204262 66.217270,50.864552 
              C63.897652,58.303738 61.917049,64.619888 59.964138,70.944588 
              C59.489799,72.480766 59.414837,74.238503 58.596275,75.542992 
              C57.750336,76.891106 56.222668,77.811440 54.993225,78.918907 
              C54.006592,77.637581 52.491631,76.490639 52.125557,75.051468 
              C50.608898,69.088913 49.313118,63.061775 48.176056,57.013268 
              C46.886635,50.154285 45.853580,43.247105 44.499760,35.101551 
              C42.810196,42.508347 41.247185,48.729115 40.012257,55.014343 
              C39.288544,58.697708 37.647739,60.455673 33.687782,60.034370 
              C30.721390,59.718773 27.647732,60.273529 24.716999,59.822460 
              C23.379597,59.616615 22.271688,57.919720 21.059690,56.899101 
              C22.261553,55.946777 23.407120,54.256191 24.674232,54.159573 
              C34.332645,53.423088 34.210835,53.495628 36.100433,43.453812 
              C37.564560,35.673080 39.258415,27.926208 41.197109,20.251936 
              C41.591961,18.688934 43.535263,17.517111 44.764751,16.164963 
              C45.833427,17.608376 47.403339,18.905935 47.873993,20.523367 
              C49.135086,24.857168 49.925255,29.328005 50.924416,34.184502 
            z"/>
          </svg>
        `),
      },
      defaultSensorIcon: {
        url: 'data:image/svg+xml;charset=UTF-8,' + 
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
            <!-- Circle with #0077B6 background and white border -->
            <circle cx="50" cy="50" r="45" fill="#0077B6" stroke="white" stroke-width="5" />
            <path fill="#F0F0F0" opacity="1.000000" stroke="none" d="
              M50.924416,34.184502 
              C52.378639,43.081142 53.810932,51.537689 55.243221,59.994232 
              C55.643261,60.082031 56.043301,60.169827 56.443336,60.257626 
              C57.976986,55.535561 59.329063,50.743435 61.113857,46.118286 
              C62.148499,43.437088 63.879124,41.024464 65.296547,38.490974 
              C67.177879,40.695656 69.456375,42.677273 70.848793,45.156536 
              C73.015907,49.015163 74.580902,53.211956 77.144318,57.869362 
              C78.668846,50.186451 83.995430,50.603954 89.152847,51.143703 
              C90.453880,51.279865 91.625725,52.650551 92.856934,53.453926 
              C91.861115,54.614372 90.675743,56.889599 89.899689,56.757633 
              C84.069611,55.766289 83.415703,60.412109 81.520126,63.826092 
              C80.303482,66.017288 78.888641,68.098442 77.562645,70.228920 
              C76.974403,70.295830 76.386162,70.362740 75.797920,70.429657 
              C72.804649,64.316956 69.811386,58.204262 66.217270,50.864552 
              C63.897652,58.303738 61.917049,64.619888 59.964138,70.944588 
              C59.489799,72.480766 59.414837,74.238503 58.596275,75.542992 
              C57.750336,76.891106 56.222668,77.811440 54.993225,78.918907 
              C54.006592,77.637581 52.491631,76.490639 52.125557,75.051468 
              C50.608898,69.088913 49.313118,63.061775 48.176056,57.013268 
              C46.886635,50.154285 45.853580,43.247105 44.499760,35.101551 
              C42.810196,42.508347 41.247185,48.729115 40.012257,55.014343 
              C39.288544,58.697708 37.647739,60.455673 33.687782,60.034370 
              C30.721390,59.718773 27.647732,60.273529 24.716999,59.822460 
              C23.379597,59.616615 22.271688,57.919720 21.059690,56.899101 
              C22.261553,55.946777 23.407120,54.256191 24.674232,54.159573 
              C34.332645,53.423088 34.210835,53.495628 36.100433,43.453812 
              C37.564560,35.673080 39.258415,27.926208 41.197109,20.251936 
              C41.591961,18.688934 43.535263,17.517111 44.764751,16.164963 
              C45.833427,17.608376 47.403339,18.905935 47.873993,20.523367 
              C49.135086,24.857168 49.925255,29.328005 50.924416,34.184502 
            z"/>
          </svg>
        `),
      },
      alertSensorIcon: {
        url: 'data:image/svg+xml;charset=UTF-8,' + 
        encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
            <!-- Circle with #0077B6 background and white border -->
            <circle cx="50" cy="50" r="45" fill="#ff0000" stroke="white" stroke-width="5" />
            <path fill="#F0F0F0" opacity="1.000000" stroke="none" d="
              M50.924416,34.184502 
              C52.378639,43.081142 53.810932,51.537689 55.243221,59.994232 
              C55.643261,60.082031 56.043301,60.169827 56.443336,60.257626 
              C57.976986,55.535561 59.329063,50.743435 61.113857,46.118286 
              C62.148499,43.437088 63.879124,41.024464 65.296547,38.490974 
              C67.177879,40.695656 69.456375,42.677273 70.848793,45.156536 
              C73.015907,49.015163 74.580902,53.211956 77.144318,57.869362 
              C78.668846,50.186451 83.995430,50.603954 89.152847,51.143703 
              C90.453880,51.279865 91.625725,52.650551 92.856934,53.453926 
              C91.861115,54.614372 90.675743,56.889599 89.899689,56.757633 
              C84.069611,55.766289 83.415703,60.412109 81.520126,63.826092 
              C80.303482,66.017288 78.888641,68.098442 77.562645,70.228920 
              C76.974403,70.295830 76.386162,70.362740 75.797920,70.429657 
              C72.804649,64.316956 69.811386,58.204262 66.217270,50.864552 
              C63.897652,58.303738 61.917049,64.619888 59.964138,70.944588 
              C59.489799,72.480766 59.414837,74.238503 58.596275,75.542992 
              C57.750336,76.891106 56.222668,77.811440 54.993225,78.918907 
              C54.006592,77.637581 52.491631,76.490639 52.125557,75.051468 
              C50.608898,69.088913 49.313118,63.061775 48.176056,57.013268 
              C46.886635,50.154285 45.853580,43.247105 44.499760,35.101551 
              C42.810196,42.508347 41.247185,48.729115 40.012257,55.014343 
              C39.288544,58.697708 37.647739,60.455673 33.687782,60.034370 
              C30.721390,59.718773 27.647732,60.273529 24.716999,59.822460 
              C23.379597,59.616615 22.271688,57.919720 21.059690,56.899101 
              C22.261553,55.946777 23.407120,54.256191 24.674232,54.159573 
              C34.332645,53.423088 34.210835,53.495628 36.100433,43.453812 
              C37.564560,35.673080 39.258415,27.926208 41.197109,20.251936 
              C41.591961,18.688934 43.535263,17.517111 44.764751,16.164963 
              C45.833427,17.608376 47.403339,18.905935 47.873993,20.523367 
              C49.135086,24.857168 49.925255,29.328005 50.924416,34.184502 
            z"/>
          </svg>
        `),
      },
}

export default SensorIcons