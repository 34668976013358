import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "./providers/AuthContex";
import { useEffect } from "react";
import SecurityGroups from "./pages/SecurityGroups";
import MapEditorScreen from "./pages/MapEditor.js/MapEditorScreen";
import { KeycloakProvider, useKeycloak } from "./providers/KeycloakContext";
import UserServices from "./services/UserServices";

function App() {
  const { accessToken, group, login, saveGroup, logout } = useAuth();
  const keycloak = useKeycloak();
  // const navigate = useNavigate();

  useEffect(() => {
    // Function to handle the session logout
    if (!keycloak) return;

    const handleLogout = async () => {
      logout();
      await keycloak.logout();
    };

    window.addEventListener("beforeunload", handleLogout);

    return () => {
      window.removeEventListener("beforeunload", handleLogout);
    };
  }, [keycloak]);

  // useEffect(() => {
  //   console.log("AT: ", accessToken);
  //   if (accessToken) {
  //     getGroupInfo();
  //   }
  // }, [accessToken]);

  // const getGroupInfo = async () => {
  //   try {
  //     const response = await UserServices.getGroupInfo();
  //     console.log("response: ", response.data);
  //     saveGroup(response.data);
  //     Navigate("/");
  //   } catch (error) {
  //     console.log("getGroupInfo login error: ", error);
  //   }
  // };

  return (
    <BrowserRouter>
      <KeycloakProvider>
        <Routes>
          <Route
            path="/"
            element={
              accessToken != null ? <Home /> : <Navigate to="/login" replace />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/adm/security-group" element={<SecurityGroups />} />
          <Route path="/map-editor" element={<MapEditorScreen />} />
        </Routes>
      </KeycloakProvider>
    </BrowserRouter>
  );
}

export default App;
