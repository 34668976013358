import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Login.css";

import { useAuth } from "../providers/AuthContex";
import UserServices from "../services/UserServices";

const Login = (props) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { accessToken, saveGroup } = useAuth();

  useEffect(() => {
    if (accessToken) {
      getGroupInfo();
    }
  }, [accessToken]);

  const getGroupInfo = async () => {
    try {
      const response = await UserServices.getGroupInfo();
      console.log("response login: ", response.data);
      if (response.data.language == "español") {
        i18n.changeLanguage("es");
      } else {
        i18n.changeLanguage("ca");
      }
      saveGroup(response.data);
      navigate("/");
    } catch (error) {
      console.log("getGroupInfo login error: ", error);
    }
  };

  return (
    <div className={"mainContainer"}>
      {/* <div className={"titleContainer"}>
        <div>Login</div>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={email}
          placeholder="Ingrese su usuario o email"
          onChange={(ev) => setEmail(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={password}
          type={"password"}
          placeholder="Enter su contraseña"
          onChange={(ev) => setPassword(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={onButtonClick}
          value={"Entrar"}
        />
      </div> */}
    </div>
  );
};

export default Login;
