import api from "./api";

const InhibitorServices = {
    getInhibitors: async() => {
        const response = await api({baseURL: '/services/inhibitors', method: 'GET'})
        return response
    },
    postInhibitors: async(body) => {
        const response = await api({
            baseURL: '/api/v1/conf/inhibitors',
            method: 'POST', 
            data: body
        })
        return response
    },
    putInhibitors: async(body) => {
        const response = await api({
            baseURL: '/api/v1/conf/inhibitors',
            method: 'PUT', 
            data: body
        })
        return response
    },
    deleteInhibitors: async(body) => {
        const response = await api({
            baseURL: `/api/v1/conf/inhibitors/${body.inhibitorid}`,
            method: 'DELETE', 
        })
        return response
    },
    linkInhibitorWithGroup: async(body) => {
        const response = await api({
            baseURL: '/services/link/group/inhibitors',
            method: 'POST', 
            data: body
        })
        return response
    },
}

export default InhibitorServices