const InhibitorIcons = {
  selectedInhibIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
          <!-- Circle with #0077B6 background and white border -->
          <circle cx="50" cy="50" r="45" fill="#ffb703" stroke="white" stroke-width="5" />
          <!-- New path in the center -->
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M80.333130,75.166946 
            C78.018623,77.416763 75.978493,79.419403 73.938362,81.422043 
            C73.653503,81.182083 73.368645,80.942116 73.083786,80.702156 
            C75.057343,75.904846 77.708282,71.265182 78.868729,66.278648 
            C81.600685,54.539238 80.303185,43.076813 74.731918,32.196892 
            C73.835892,30.447069 73.677628,28.319456 73.178322,26.366484 
            C74.951599,27.573126 77.102547,28.462214 78.431374,30.042568 
            C83.591095,36.178905 86.922173,43.400627 87.842102,51.278385 
            C88.728004,58.864731 85.808777,65.860298 82.062447,72.415092 
            C81.583359,73.253349 81.092773,74.085052 80.333130,75.166946 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M24.692898,69.232399 
            C20.081261,61.278488 18.101673,52.898563 21.239996,44.674557 
            C23.576624,38.551399 28.085020,33.256996 32.498070,27.939020 
            C25.076828,44.779491 26.732622,61.308678 33.584492,77.853485 
            C32.930672,78.087135 32.276855,78.320778 31.623039,78.554428 
            C29.399868,75.541740 27.176697,72.529060 24.692898,69.232399 
            z"/>
          <path fill="#FDFDFD" opacity="1.000000" stroke="none" 
            d="M51.198120,45.010963 
            C54.912815,43.458134 57.734119,44.317646 60.254749,46.757118 
            C63.302437,49.706673 64.241440,53.533791 62.249607,56.943039 
            C60.930737,59.200428 57.933937,61.076721 55.313873,61.842499 
            C50.705708,63.189358 46.938259,61.281792 45.236561,56.545277 
            C43.592625,51.969536 45.654930,47.920513 51.198120,45.010963 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M40.995312,59.118401 
            C41.408024,62.275837 41.885136,64.974625 42.209103,67.691681 
            C42.318439,68.608665 42.039524,69.571953 41.938049,70.514076 
            C41.101772,70.287086 39.963799,70.333267 39.474098,69.792458 
            C31.039476,60.477448 30.174294,46.328518 39.134369,37.459103 
            C40.103889,36.499393 41.732147,36.205151 43.054844,35.602226 
            C43.026352,37.041523 43.329136,38.585571 42.912792,39.902222 
            C40.970966,46.043056 38.583618,52.095142 40.995312,59.118401 
            z"/>
          <path fill="#FDFDFD" opacity="1.000000" stroke="none" 
            d="M72.112495,42.958248 
            C76.984634,54.210869 75.790413,62.346577 68.142296,69.293304 
            C67.175346,70.171577 65.867119,70.674118 64.169647,71.020370 
            C67.222404,62.365925 69.345146,53.863064 67.218620,44.303219 
            C69.002167,43.449417 70.557327,43.203831 72.112495,42.958248 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M72.055237,42.605812 
            C70.557327,43.203831 69.002167,43.449417 67.168030,43.870903 
            C65.882668,41.541012 64.876282,39.035217 63.632690,35.938805 
            C68.350594,36.376614 70.063675,39.460503 72.055237,42.605812 
            z"/>
        </svg>
      `),
  },
  newInhibIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
          <!-- Circle with #0077B6 background and white border -->
          <circle cx="50" cy="50" r="45" fill="#AFE1AF" stroke="white" stroke-width="5" />
          <!-- New path in the center -->
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M80.333130,75.166946 
            C78.018623,77.416763 75.978493,79.419403 73.938362,81.422043 
            C73.653503,81.182083 73.368645,80.942116 73.083786,80.702156 
            C75.057343,75.904846 77.708282,71.265182 78.868729,66.278648 
            C81.600685,54.539238 80.303185,43.076813 74.731918,32.196892 
            C73.835892,30.447069 73.677628,28.319456 73.178322,26.366484 
            C74.951599,27.573126 77.102547,28.462214 78.431374,30.042568 
            C83.591095,36.178905 86.922173,43.400627 87.842102,51.278385 
            C88.728004,58.864731 85.808777,65.860298 82.062447,72.415092 
            C81.583359,73.253349 81.092773,74.085052 80.333130,75.166946 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M24.692898,69.232399 
            C20.081261,61.278488 18.101673,52.898563 21.239996,44.674557 
            C23.576624,38.551399 28.085020,33.256996 32.498070,27.939020 
            C25.076828,44.779491 26.732622,61.308678 33.584492,77.853485 
            C32.930672,78.087135 32.276855,78.320778 31.623039,78.554428 
            C29.399868,75.541740 27.176697,72.529060 24.692898,69.232399 
            z"/>
          <path fill="#FDFDFD" opacity="1.000000" stroke="none" 
            d="M51.198120,45.010963 
            C54.912815,43.458134 57.734119,44.317646 60.254749,46.757118 
            C63.302437,49.706673 64.241440,53.533791 62.249607,56.943039 
            C60.930737,59.200428 57.933937,61.076721 55.313873,61.842499 
            C50.705708,63.189358 46.938259,61.281792 45.236561,56.545277 
            C43.592625,51.969536 45.654930,47.920513 51.198120,45.010963 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M40.995312,59.118401 
            C41.408024,62.275837 41.885136,64.974625 42.209103,67.691681 
            C42.318439,68.608665 42.039524,69.571953 41.938049,70.514076 
            C41.101772,70.287086 39.963799,70.333267 39.474098,69.792458 
            C31.039476,60.477448 30.174294,46.328518 39.134369,37.459103 
            C40.103889,36.499393 41.732147,36.205151 43.054844,35.602226 
            C43.026352,37.041523 43.329136,38.585571 42.912792,39.902222 
            C40.970966,46.043056 38.583618,52.095142 40.995312,59.118401 
            z"/>
          <path fill="#FDFDFD" opacity="1.000000" stroke="none" 
            d="M72.112495,42.958248 
            C76.984634,54.210869 75.790413,62.346577 68.142296,69.293304 
            C67.175346,70.171577 65.867119,70.674118 64.169647,71.020370 
            C67.222404,62.365925 69.345146,53.863064 67.218620,44.303219 
            C69.002167,43.449417 70.557327,43.203831 72.112495,42.958248 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M72.055237,42.605812 
            C70.557327,43.203831 69.002167,43.449417 67.168030,43.870903 
            C65.882668,41.541012 64.876282,39.035217 63.632690,35.938805 
            C68.350594,36.376614 70.063675,39.460503 72.055237,42.605812 
            z"/>
        </svg>
      `),
  },
  defaultInhibIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 100 100">
          <!-- Circle with #0077B6 background and white border -->
          <circle cx="50" cy="50" r="45" fill="#0077B6" stroke="white" stroke-width="5" />
          <!-- New path in the center -->
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M80.333130,75.166946 
            C78.018623,77.416763 75.978493,79.419403 73.938362,81.422043 
            C73.653503,81.182083 73.368645,80.942116 73.083786,80.702156 
            C75.057343,75.904846 77.708282,71.265182 78.868729,66.278648 
            C81.600685,54.539238 80.303185,43.076813 74.731918,32.196892 
            C73.835892,30.447069 73.677628,28.319456 73.178322,26.366484 
            C74.951599,27.573126 77.102547,28.462214 78.431374,30.042568 
            C83.591095,36.178905 86.922173,43.400627 87.842102,51.278385 
            C88.728004,58.864731 85.808777,65.860298 82.062447,72.415092 
            C81.583359,73.253349 81.092773,74.085052 80.333130,75.166946 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M24.692898,69.232399 
            C20.081261,61.278488 18.101673,52.898563 21.239996,44.674557 
            C23.576624,38.551399 28.085020,33.256996 32.498070,27.939020 
            C25.076828,44.779491 26.732622,61.308678 33.584492,77.853485 
            C32.930672,78.087135 32.276855,78.320778 31.623039,78.554428 
            C29.399868,75.541740 27.176697,72.529060 24.692898,69.232399 
            z"/>
          <path fill="#FDFDFD" opacity="1.000000" stroke="none" 
            d="M51.198120,45.010963 
            C54.912815,43.458134 57.734119,44.317646 60.254749,46.757118 
            C63.302437,49.706673 64.241440,53.533791 62.249607,56.943039 
            C60.930737,59.200428 57.933937,61.076721 55.313873,61.842499 
            C50.705708,63.189358 46.938259,61.281792 45.236561,56.545277 
            C43.592625,51.969536 45.654930,47.920513 51.198120,45.010963 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M40.995312,59.118401 
            C41.408024,62.275837 41.885136,64.974625 42.209103,67.691681 
            C42.318439,68.608665 42.039524,69.571953 41.938049,70.514076 
            C41.101772,70.287086 39.963799,70.333267 39.474098,69.792458 
            C31.039476,60.477448 30.174294,46.328518 39.134369,37.459103 
            C40.103889,36.499393 41.732147,36.205151 43.054844,35.602226 
            C43.026352,37.041523 43.329136,38.585571 42.912792,39.902222 
            C40.970966,46.043056 38.583618,52.095142 40.995312,59.118401 
            z"/>
          <path fill="#FDFDFD" opacity="1.000000" stroke="none" 
            d="M72.112495,42.958248 
            C76.984634,54.210869 75.790413,62.346577 68.142296,69.293304 
            C67.175346,70.171577 65.867119,70.674118 64.169647,71.020370 
            C67.222404,62.365925 69.345146,53.863064 67.218620,44.303219 
            C69.002167,43.449417 70.557327,43.203831 72.112495,42.958248 
            z"/>
          <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
            d="M72.055237,42.605812 
            C70.557327,43.203831 69.002167,43.449417 67.168030,43.870903 
            C65.882668,41.541012 64.876282,39.035217 63.632690,35.938805 
            C68.350594,36.376614 70.063675,39.460503 72.055237,42.605812 
            z"/>
        </svg>
      `),
  },
};

export default InhibitorIcons;
