import React, { useState, useEffect } from "react";
import DroneIcons from "../SVG-icons/DroneIcons";
export default function AlertTag({
  item,
  index,
  icon,
  selectItem,
  selectedItem,
}) {
  const [remaining, setRemaining] = useState(30);
  const [shouldStart, setShouldStart] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const formatTimmer = () => {
    // let ms = timestamp - startTime;
    // const totalSeconds = Math.floor(ms / 1000);
    const minutesFormat = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secondsFormat = String(seconds % 60).padStart(2, "0");
    return `${minutesFormat}:${secondsFormat}`;
  };

  useEffect(() => {
    let interval = null;
    let countDownInterval = null;
    let ms = new Date() - new Date(item.startTime);
    const totalSeconds = Math.floor(ms / 1000);
    const timeDif = new Date() - new Date(item.timestamp);
    setSeconds(totalSeconds);
    setShouldStart(timeDif > 1500);
    if (!(timeDif > 1500)) {
      setRemaining(30);

      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);

      clearInterval(countDownInterval);
    } else if (timeDif > 1500 && seconds !== 0) {
      countDownInterval = setInterval(() => {
        setRemaining((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
      //   clearInterval(interval);
    }

    // Cleanup function
    return () => {
      clearInterval(interval);
      clearInterval(countDownInterval);
    };
  }, [shouldStart, seconds, item.timestamp]);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       if (!shouldStart) {
  //         if (new Date() - item.timestamp > 3000) {
  //           setShouldStart(true);
  //         }
  //       }
  //     }, 1000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, [item.timestamp]);

  //   useEffect(() => {
  //     if (!shouldStart) return;

  //     const interval = setInterval(() => {
  //       setRemaining((prev) => {
  //         if (prev <= 1) {
  //           clearInterval(interval);
  //           return 0;
  //         }
  //         return prev - 1;
  //       });
  //     }, 1000);

  //     return () => {
  //       clearInterval(interval);
  //       setShouldStart(false);
  //       setRemaining(30);
  //     };
  //   }, [shouldStart]);

  return (
    <>
      {remaining > 0 ? (
        <div
          key={index}
          style={{
            backgroundColor:
              item.alertId == selectedItem?.alertId ? "#ffa500" : "#FFFFFF",
            color: "black",
            padding: 5,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => selectItem(item)}
        >
          <img src={icon} width={15} height={15} style={{ marginRight: 8 }} />
          <div style={{ display: "flex", flex: 1 }}>
            <span
              style={{
                marginRight: 4,
                flex: 3,
              }}
            >
              {item.manufacturer} {item.model}
            </span>
            {!shouldStart ? (
              <span style={{ flex: 1, textAlign: "right" }}>
                {formatTimmer()}
              </span>
            ) : (
              <span
                style={{
                  flex: 1,
                  color: "red",
                  textAlign: "right",
                }}
              >
                -{remaining}s
              </span>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
